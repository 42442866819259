import {AfterContentInit, Component} from '@angular/core';
import {Platform} from '@ionic/angular';
import {DeviceService} from '@services/device.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterContentInit {

  constructor(
    public platform: Platform,
    public deviceService: DeviceService
  ) {
    console.log('Main App constructor...');
    console.log('Current Device: ' + deviceService.getCurrentPlatform());
  }

  ngAfterContentInit() {
    if (this.platform.is('android')) {
      console.log('Platform is Android');
      console.log('App after content init...');
    }
  }
}
