import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from '@services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => {

      let errorMessage = 'Something bad happened; please try again later.';

      if (error.status === 401) {
        // auto logout if 401 response returned from api
        // this.authenticationService.logout();
        // location.reload();
        errorMessage = 'Authorization error';
        console.log(errorMessage);
      }
      if (error.status === 400) {
        errorMessage = 'Something wrong with your request';
        console.log(errorMessage);
      }

      if (error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${JSON.stringify(error.error.message)}`);

        if (error.error.message) {
          errorMessage = error.error.message;
        }
      }

      // Return an observable with a user-facing error message.
      return throwError(errorMessage);

    }));
  }
}
