import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {User} from '@models/user';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  public authenticatedUsers: User[] = [];
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    const localUsers = localStorage.getItem('authenticatedUsers');
    if (localUsers === null) {
      localStorage.setItem('authenticatedUsers', '[]');
    } else {
      this.authenticatedUsers = JSON.parse(localStorage.getItem('authenticatedUsers'));
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    const body = {
      email: email,
      password: password
    };

    /**
     * Returns an Observable
     */
    return this.http.post<User>(`${environment.apiUrl}/login`, JSON.stringify(body), httpOptions)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log('Authenticated user');
        console.log(user);
        console.log(this.authenticatedUsers);

        let isInArray = false;


        if (this.authenticatedUsers.length > 0) {
          this.authenticatedUsers.forEach((item) => {
            if (item.user.id === user.user.id) {
              isInArray = true;
            }
          });
        }

        if (!isInArray) {
          this.authenticatedUsers.push(user);
        }

        console.log(this.authenticatedUsers);

        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('authenticatedUsers', JSON.stringify(this.authenticatedUsers));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout(): void {
    const currentUser = this.currentUserSubject.value;
    const remainingUsers = this.authenticatedUsers.filter((userItem) => {
      return userItem.user.id !== currentUser.user.id;
    });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('authenticatedUsers');
    localStorage.setItem('authenticatedUsers', JSON.stringify(remainingUsers));
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  changeUser(user): void {
    this.currentUserSubject.next(user);
  }
}
