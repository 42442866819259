import {Injectable} from '@angular/core';
import {Network} from '@capacitor/network';
import {Device} from '@capacitor/device';
import {Observable, interval, from} from 'rxjs';
import {Geolocation} from '@capacitor/geolocation';
import {startWith} from 'rxjs/operators';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    public platform: Platform
  ) {
  }

  getCurrentPosition(): Observable<any> {
    return from(Geolocation.getCurrentPosition());
  }

  getNetworkStatus(): Observable<any> {
    return from(Network.getStatus()).pipe();
  };

  getBatteryInfo(): Observable<any> {
    return from(Device.getBatteryInfo());
  }

  getCurrentPlatform(): string {
    let platformName = 'Other';
    if (this.platform.is('android')) {
      platformName = 'Android';
    } else if (this.platform.is('ios')) {
      platformName = 'iOS';
    } else if (this.platform.is('desktop')) {
      platformName = 'Desktop';
    }
    return platformName;
  }
}
